* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#app,
#app > div {
  /* min-height: 100vh; */
  margin: 0;
  width: 100%;
}

#root {
  /* height: 100vh; */
  min-height: 100vh;
}

.App {
  text-align: center;
  /* height: 100%;
  margin: 0; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* New CSS START */

@font-face {
  font-family: "Inconsolata";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inconsolata/v31/QlddNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLyya15IDhunA.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Inconsolata";
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inconsolata/v31/QlddNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLyya15IDhunA.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
body,
html {
  width: 100%;
  min-height: 100%;
}
@media (min-width: 880px) {
  body,
  html {
    height: 100%;
  }
}
#app canvas {
  display: block;
  position: fixed;
  z-index: -1;
  top: 0;
}

body {
  box-sizing: border-box;
  font-family: "Inconsolata", Helvetica, monospace;
  margin: 0;
  /* display: flex; */
  /* background-color: #f7f7f7; */
  /* padding: 25px; */
  /* Background gif */
  /* background-image: url("https://www.gifcen.com/wp-content/uploads/2022/04/wallpaper-gif-4.gif"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
p,
a {
  color: #474747;
}
#outer {
  /* width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; */
  /* margin: auto; */
  /* display: grid; */
  /* align-items: center;
  justify-content: center; */

  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}
@media (min-width: 881px) {
  #outer {
    /* height: 100%; */
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 480px) {
  #outer {
    width: 100%;
  }
}

#inner {
  flex: 1 0 33.333%;
  max-width: 100%;
  box-sizing: border-box;
  column-gap: 50px;
  margin: 50px;
}
#inner > div {
  display: flex;
  flex-direction: column;
}
@media (min-width: 881px) {
  #inner > div {
    width: 50%;
  }
}
#inner #avatar {
  justify-content: center;
  align-items: center;
}
#inner p {
  letter-spacing: 0.125rem;
  width: calc(100% + 0.125rem);
  font-size: 16px;
  line-height: 1.875;
}
@media (min-width: 881px) {
  #inner {
    width: 992px;
  }
  #inner img {
    max-width: 100%;
  }
}
@media (max-width: 880px) {
  #inner {
    flex-direction: column-reverse;
  }
  #inner img {
    width: 300px;
    max-width: 100%;
  }
}
#links {
  display: flex;
  flex: 1;
  align-items: flex-end;
  column-gap: 20px;
  justify-content: center;
}
@media (max-width: 400px) {
  #links {
    row-gap: 20px;
    align-items: flex-start;
    flex-direction: column;
  }
}
#links a {
  background-color: white;
  /* max-width: 120px; */
  padding: 15px 20px;
  font-size: 17px;
  letter-spacing: 2px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  line-height: 25px;
  transition: background-color 0.25s ease;
  text-decoration: none;
}
#links a:hover {
  color: #474747;
  background-color: rgba(238, 123, 30, 0.2);
}
@media (max-width: 880px) {
  #links a {
    padding: 15px 10px;
  }
  #inner {
    padding-right: 5px;
    padding-left: 5px;
  }
}
@media (max-width: 400px) {
  #links a {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
}
#links svg {
  margin-right: 10px;
  color: #ffffff;
  fill: #441eee;
}
h1 {
  font-size: 70px;
  font-weight: 700;
  color: #474747;
  white-space: nowrap;
}
@media (max-width: 880px) {
  h1 {
    font-size: 56px;
    text-align: center;
    margin-top: 36px;
  }
}

/* H1 animation START */
h1 {
  position: relative;
  color: hsl(0, 0%, 100%);
  font-weight: bold;
  font-family: "Courier New", monospace;
  letter-spacing: 6px;
  /* overflow: hidden; */
}

/* H1 animation END */

.image-style {
  border-radius: 50%;
  box-shadow: 0 12px 12px rgba(0, 0, 0, 0.2), 0 0 0 4px rgba(255, 255, 255, 0.5);
}

/* Small devices (landscape phones) */
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .App-header {
    font-size: calc(10px + 1vmin);
  }
  .biotext {
    /* width: 90vw; */
    margin: auto;
  }
}

/* Medium devices (tablets) */
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .App-header {
    font-size: calc(10px + 1vmin);
  }
  #inner {
    margin: 20px;
    column-gap: 20px;
  }
  #inner img {
    width: 100%;
  }
  #links {
    /* flex-direction: column; */
    row-gap: 10px;
    align-items: center;
  }
}

/* Large devices (desktops) */
@media only screen and (min-width: 769px) {
  .App-header {
    font-size: calc(10px + 2vmin);
  }
  #inner {
    margin: 50px;
    column-gap: 50px;
  }
  #inner > div {
    width: 50%;
  }
  #inner img {
    max-width: 100%;
  }
  #links {
    flex-direction: row;
    row-gap: 0;
    align-items: flex-end;
  }
}

@media (max-width: 500px) {
  html,
  body {
    height: 100vh;
  }

  .App-header {
    min-height: 100vh;
  }
}

@media (max-width: 400px) {
  html,
  body {
    height: 100vh;
  }

  .App-header {
    min-height: 100vh;
  }
}

@media (max-width: 300px) {
  html,
  body {
    height: 100vh;
  }

  .App-header {
    min-height: 100vh;
  }
}

@media (max-width: 767px) {
  .inner {
    flex: 1 0 100%;
  }
}
